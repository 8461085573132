import React, { useState } from "react"
import { FaAddressBook, FaPhone, FaLocationArrow, FaTimes } from "react-icons/fa";
import { SiUbereats, SiDeliveroo } from "react-icons/si";
import { RiTakeawayLine } from "react-icons/ri"
import { MdShoppingBasket } from "react-icons/md"
import Modal from 'react-modal';
import { Link } from "gatsby"

export default function FooterMenu({ data }) {
  const [iframe, showIframe] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    }
  };
  return (
    <div className="footer-menu">
      {data.restaurant.urlName && data.restaurant.websiteSettings?.reservation && (
        <div role="button" tabIndex={0} onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} data-item="Réservation" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8"><img src="/assets/event.png" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Réservation</div>
        </div>
      )}

      {!data.restaurant.settings?.delivery && !data.restaurant.settings?.takeaway && (
        <Link to="/menu/" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8" ><img src="/assets/carte.png" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Carte</div>
        </Link>
      )}

      {data.restaurant.settings?.delivery && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8"><img src="/assets/delivery.png" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Livraison</div>
        </a>
      )}

      {data.restaurant.settings?.takeaway && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8" ><img src="/assets/take-away.png" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Emporté</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.uberEatsUrl && (
        <a href={`${data.restaurant.websiteSettings?.uberEatsUrl}`} target="_blank" rel="noreferrer" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8"><SiUbereats size="2.4em" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.deliverooUrl && (
        <a href={`${data.restaurant.websiteSettings?.deliverooUrl}`} target="_blank" rel="noreferrer" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8"><SiDeliveroo size="2.4em" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.googleMapsUrl && (!data.restaurant.websiteSettings?.uberEatsUrl && !data.restaurant.websiteSettings?.deliverooUrl) && (
        <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer" className="w-10 h-10 flex flex-col items-center justify-center">
          <div className="h-8 w-8"><img src="/assets/location.png" /></div>
          <div className="text-black text-center leading-none flex flex-col justify-end h-2">Itinéraire</div>
        </a>
      )}
      <a href={`tel:${data.restaurant.informations.phone}`} className="w-10 h-10 flex flex-col items-center justify-center">
        <div className="h-8 w-8"><img src="/assets/call.png" /></div>
        <div className="text-black text-center leading-none flex flex-col justify-end h-2">Appeler</div>
      </a>

      <Modal
        isOpen={iframe}
        onRequestClose={() => showIframe(!iframe)}
        style={customStyles}
        contentLabel="Reservation"
      >
        <iframe src={`https://reservation.we-eats.com/${data.restaurant.urlName}`} width="100%" height="100%" frameBorder="0" title="réservation" />
        <span className="modal-close-btn" tabIndex={0} onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button">
          <FaTimes color="white" size="1.8em" />
        </span>

      </Modal>
    </div>

  )
}
